@media screen and (max-width: 1290px) {
    .navItemStyle{
        font-size: 12px !important;
       
    }
  
}
header nav .container .inner-header #bs-example-navbar-collapse-1 ul .dropdown-menu  {
    position:absolute;
    top:8rem;
    right:-5rem;
    width:120px;
    padding:15px;
    border-radius:80px;
    background-color:white;
    border:2px solid gray;
    text-align: center;
  }

  header nav .container .inner-header #bs-example-navbar-collapse-1 ul .dropdown-menu ::before  {
    /* content: ''; */
    position:absolute;
    top:-0.7rem;
    height: 20px;
    right:1.1rem;
    width:20px;
    transform: rotate(45deg);
    background-color:white;
    border-left:1px solid gray;
    border-top: 1px solid gray;
  }