html {
    box-sizing: border-box;
    font-size: 10px;

}

ul {
    list-style-type: none;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.carousel__wrap {
    margin-top: 300%;
    position: absolute;
    width: 700%;
    margin-left: 700%;
    height: 50rem;
}

.carousel__inner {
    height: 20rem;
    position: relative;
    width: 100%;
}

.carousel__container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.carousel__slide-list {
    height: 70%;
    left: 58%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    background: white;
    width: calc((6 + 0.5) * 30rem* 2);

}

.carousel__slide-item {
    display: inline-block;
    height: 30rem;
    margin: 0;
    padding: 1rem;
    position: absolute;
    transition: all 0.3s;
    width: 32rem;
}

.carousel__slide-item-img-link {
    /* cursor: zoom-in; */
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 50%;
    padding: 10px 0px 5px;
}

.carousel__slide-item-img-link img {
    height: 50%;
    width: 100%;
}

.carousel__slide-item-img-link::after {
    align-items: center;
    color: white;
    content: 'read more';
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    width: 100%;
}


/* .carousel__slide-item-img-link:hover::after {
        opacity: 1;
    }

    .carousel__slide-item-img-link:hover img {
        transform: scale(1.3);
    } */


.carousel-slide-item__body {
    bottom: -2.5rem;
    height: 10%;
    position: absolute;


}

.carousel-slide-item__body h4 {
    margin: 0.7rem 0 0;
    text-transform: uppercase;
}

.carousel-slide-item__body p {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0.7rem 0 0;
}

.carousel__btn {
    align-items: center;
    background: 0;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);


}

.carousel__btn--prev {
    left: -10rem;
}

.carousel__btn--next {
    right: -10rem;
}

.carousel__btn-arrow {
    border: solid white;
    border-width: 0 0.4rem 0.4rem 0;
    height: 6rem;
    padding: 3px;
    width: 6rem;
    z-index: 10;
    margin-top: 20%;
}

.carousel__btn-arrow--left {
    transform: rotate(135deg);
}

.carousel__btn-arrow--right {
    transform: rotate(-45deg);
}

.carousel__dots {
    display: inline-block;
    left: 50%;
    margin-top: 2rem;
    position: absolute;
    transform: translateX(-50%);


}

.carousel__dots .dot {
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    margin: 0 0.3rem;
    outline: none;
    transform: scale(0.5);
    width: 2rem;

}

.carousel__dots .dot .active {
    background: black;
}

@media (max-width: 1000px) {
    .carousel__slide-list {
        height: 70%;
        left: 58%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((6 + 1.1) * 30rem* 1.7);

    }

    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 80%;
        margin-left: 10%;
    }
}

@media (max-width: 800px) {
    .carousel__btn--prev {
        left: -2rem;
    }

    .carousel__btn--next {
        right: -2rem;
    }
}
@media (max-width: 680px) {
  
    .carousel__slide-list {
        height: 70%;
        left: 58%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((6 + 1) * 30rem* 2);

    }
    .carousel__btn {
        align-items: center;
        background: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-65%);
    
    
    }
    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 200px;
        margin-left: 30%;
    }
    .carousel__btn--prev {
        left: 1rem;
    }

    .carousel__btn--next {
        right: -3rem;
    }
}
@media (max-width: 550px) {
    .carousel__inner {
        /* height: 100%;
        overflow: hidden;
        position: relative;
        width: 80%; */
        margin-left: -10%;
    }
    .carousel__slide-list {
        height: 70%;
        left: 58%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((6 + 1) * 30rem* 2);
    }
    .carousel__btn {
        align-items: center;
        background: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-65%);   
    }
    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 200px;
        margin-left: 30%;
    }
    .carousel__btn--prev {
        left: 1rem;
    }
    .carousel__btn--next {
        right: -50px;
    }
}

@media (max-width: 500px) {
    html {
        font-size: 8px;
    }
    .carousel__slide-list {
        height: 70%;
        left: 50%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((6 + 1) * 30rem* 2);
    }
}
@media (max-width: 400px) {
    .carousel__slide-list {
        height: 70%;
        left: 51%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((6 + 1) * 30rem* 2);

    }
    .carousel__btn {
        align-items: center;
        background: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-65%);
    
    
    }
    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 200px;
        margin-left: 10%;
    }
    .carousel__btn--prev {
        left: -3rem;
    }

    .carousel__btn--next {
        right: -50px;
    }
}

@media (max-width: 330px) {
    html {
        font-size: 5px;
       
    }
    .carousel__slide-list {
        height: 70%;
        left: 51%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((6 + 1) * 30rem* 1.7);

    }
    .carousel__btn {
        align-items: center;
        background: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-65%);         
    
    }
    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 200px;
        margin-left: 10%;
    }
    .carousel__btn--prev {
        left: -3rem;
    }

    .carousel__btn--next {
        right: -60px;
    }
}

@media (max-width: 290px) {
    .carousel__inner {
        /* height: 100%;
        overflow: hidden;
        position: relative;
        width: 80%; */
        margin-left: -40%;
    }
    .carousel__slide-list {
        height: 70%;
        left: 0%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        background: white;
        width: calc((3 + 1) * 5rem* 2);
    }
    .carousel__btn {
        align-items: center;
        background: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-65%);   
    }
    .carousel__container {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 200px;
        margin-left: 50%;
    }
    .carousel__btn--prev {
        left: 10rem;
    }
    .carousel__btn--next {
        right: -50px;
    }
}
@media (max-width: 290px) {
    .carousel__btn--prev {
        left: 5rem;
    }
    .carousel__btn--next {
        right: -15rem;
    }    
}