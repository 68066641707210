.backLogo{
    background-color: rgba(0, 0, 0, 0.144);
}
.text-position{
    position: fixed;
    transform: rotate(-90deg);
    margin-top: 400px;
    margin-left:85%;
    cursor: pointer;   
    z-index: 1000;
}

